<template>
  <div>
    <b-card title="Crear Prealerta">
      <validation-observer ref="registerRules">
        <b-row>
          <b-col cols="12" class="mb-2">
            <b-alert variant="dark" show>
              <div class="alert-body">
                <span>Diligencia los datos de la prealerta y haz click en crear.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-tracking" label="Tracking">
              <validation-provider
                #default="{ errors }"
                name="Tracking"
                rules="required"
              >
                <b-form-input
                  id="i-tracking"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.tracking"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label-for="i-store" label="Tienda">
              <validation-provider
                #default="{ errors }"
                name="Tienda"
                rules="required"
              >
                <b-form-select
                  id="i-store"
                  placeholder=""
                  v-model="formCreateData.storeId"
                  :options="dataStores"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <br />
            <b-button
              variant="success"
              @click="showModalCreateStore = true"
              >Crear tienda</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-content" label="Contenido">
              <validation-provider
                #default="{ errors }"
                name="Contenido"
                rules="required"
              >
                <b-form-input
                  id="i-content"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.content"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-recipient" label="Destinatario">
              <validation-provider
                #default="{ errors }"
                name="Destinatario"
                rules="required"
              >
                <b-form-select
                  id="i-recipient"
                  v-model="formCreateData.recipientId"
                  :options="dataRecipients"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-comValue" label="Valor comercial">
              <validation-provider
                #default="{ errors }"
                name="Valor_comercial"
                rules="required"
              >
                <b-form-input
                  id="i-comValue"
                  type="number"
                  placeholder=""
                  v-model="formCreateData.commercialValue"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-safeValue" label="Valor asegurado">
              <validation-provider
                #default="{ errors }"
                name="Valor_asegurado"
                rules="required"
              >
                <b-form-input
                  id="i-safeValue"
                  type="number"
                  placeholder=""
                  v-model="formCreateData.safeValue"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="i-taskPosition"
              label="Posicion arancelaria"
            >
              <validation-provider
                #default="{ errors }"
                name="Posicion_arancelaria"
                rules="required"
              >
                <b-form-select
                  id="i-taskPosition"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.taxPositionId"
                  :options="dataTasksPositions"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-purchaseOrder" label="Orden de compra">
              <validation-provider
                #default="{ errors }"
                name="Orden_de_compra"
                rules="required"
              >
                <b-form-input
                  id="i-purchaseOrder"
                  type="text"
                  placeholder=""
                  v-model="formCreateData.purchaseOrder"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-billCopy" label="Copia de factura">
              <b-form-file
                v-model="formCreateData.billCopy"
                :state="Boolean(formCreateData.billCopy)"
                placeholder="Selecciona un archivo y arrastralo aqui ..."
                drop-placeholder="Arrastra el archivo aqui..."
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-notes" label="Notas">
              <b-form-textarea
                id="i-notes"
                type="text"
                placeholder=""
                v-model="formCreateData.notes"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formCreateData.status"
            >
              Activo
            </b-form-checkbox>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formCreateData.retained"
            >
              Retener
            </b-form-checkbox>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button 
              variant="danger mr-2"
              @click="goToAlerts()"
            >Cancelar</b-button>
            <b-button
              variant="primary"
              @click="onFormRegisterButtonClick()"
              >Guardar</b-button
            >
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-modal
      v-model="showModalCreateStore"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Crear"
      id="modalCreateStore"
      title="Registrar tienda"
      @ok="onFormRegisterStoreButtonClick"
    >
      <validation-observer ref="registerRulesStore">
        <b-row>
          <b-col>
            <b-form-group label-for="i-nameStore" label="Nombre de la tienda">
              <validation-provider
                #default="{ errors }"
                name="Nombre_de_la_tienda"
                rules="required"
              >
                <b-form-input
                  id="i-nameStore"
                  type="text"
                  placeholder=""
                  v-model="nameStore"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
  BButton,
  BTable,
  BPagination,
} from "bootstrap-vue";
export default {
  name: "create-alerts",
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      errors: [],
      showModalCreateStore: false,
      nameStore: "",
      dataTasksPositions: [],
      dataStores: [],
      dataRecipients: [],
      formCreateData: {
        tracking: "",
        storeId: "",
        content: "",
        recipientId: "",
        commercialValue: 0,
        safeValue: 0,
        taxPositionId: "",
        purchaseOrder: "",
        billCopy: [],
        status: 1,
        retained: 0,
      },
    };
  },
  created() {
    this.getTaxesPositions();
    this.getStoresFromService();
    this.getRecipientsFromService();
  },
  methods: {
    goToAlerts(){
      this.$router.push({name: "pre-alertas"});
    },
    getTaxesPositions() {
      const userData = this.$session.get("userData");
      this.$http
        .get("api/casillero/posiciones_arancelarias", {
          headers: { Authorization: "Bearer " + userData.access_token },
        })
        .then((response) => {
          this.formatDataTaxesPositions(response.data.datos);
        });
    },
    formatDataTaxesPositions(dataFromService) {
      this.dataTasksPositions = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {
          value: itemFromService.id,
          text: itemFromService.descripcion,
        };
        formatedData.push(itemFormated);
        this.dataTasksPositions = formatedData;
      });
    },
    getStoresFromService() {
      const userData = this.$session.get("userData");
      this.$http
        .get("api/casillero/tiendas", {
          headers: { Authorization: "Bearer " + userData.access_token },
        })
        .then((response) => {
          this.formatDataStores(response.data.datos);
        });
    },
    formatDataStores(dataFromService) {
      this.dataStores = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {
          value: itemFromService.id,
          text: `${itemFromService.id} - ${itemFromService.razon_social}`,
        };
        formatedData.push(itemFormated);
        this.dataStores = formatedData;
      });
    },
    CreateStore() {
      this.$http
        .post(
          "api/casillero/tiendas",
          {
            razon_social: this.nameStore,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.$swal({
            title: "Registrar tienda",
            text: "¡Tienda registrada con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(response);
            this.nameStore = "";
            this.showModalCreateStore = false;
            this.getStoresFromService();
          });
        })
        .catch((errors) => {
          this.$swal({
            title: "Registrar tienda",
            text: "Error al registrar la tienda",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(errors);
          });
        });
    },
    getRecipientsFromService() {
      this.$http
        .post(
          "api/casillero/clientes/destinatarios?page=1",
          {
            pageSize: 500,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.formatDataRecipients(response.data.datos.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataRecipients(dataFromService) {
      this.dataRecipients = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {
          value: itemFromService.id,
          text: `${itemFromService.primer_nombre} ${itemFromService.segundo_nombre} ${itemFromService.primer_apellido} ${itemFromService.segundo_apellido}`,
        };
        formatedData.push(itemFormated);
        this.dataRecipients = formatedData;
      });
    },
    CreateAlert() {
      console.log(this.formCreateData.billCopy);
      this.$http
        .post(
          "api/casillero/prealertas",
          {
            tracking: this.formCreateData.tracking,
            tienda_id: this.formCreateData.storeId,
            contenido: this.formCreateData.content,
            destinatario_id: this.formCreateData.recipientId,
            valor_comercial: this.formCreateData.commercialValue,
            valor_asegurado: this.formCreateData.safeValue,
            posicion_arancelaria_id: this.formCreateData.taxPositionId,
            orden_de_compra: this.formCreateData.ppurchaseOrder,
            notas: this.formCreateData.notes,
            retener: this.formCreateData.retained,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.$swal({
            title: "Crear Prealerta",
            text: "¡Prelaera creada con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(response);
            this.$router.push("/pre-alertas");
          });
        })
        .catch((errors) => {
          this.$swal({
            title: "Crear Prealerta",
            text: "Error al crear la prealerta",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(errors);
          });
        });
    },
    validationFormRegister() {
      return new Promise((resolve, reject) => {
        this.$refs.registerRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormRegisterStore() {
      return new Promise((resolve, reject) => {
        this.$refs.registerRulesStore.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFormRegisterButtonClick() {
      this.validationFormRegister().then((validForm) => {
        if (validForm) {
          this.CreateAlert();
        }
      });
    },
    onFormRegisterStoreButtonClick(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormRegisterStore().then((validForm) => {
        if (validForm) {
          this.CreateStore();
        }
      });
    },
  },
};
</script>